export const colors = [
    "#9076f5",
    "#f36868",
    "#56ea90",
    "#7bcef2",
    "#e8ff00",
    "#eeb411",
    "#88777f",
    "#fa79f6"
]
